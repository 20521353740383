<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <img alt="" :src="logoImg" width="160" style="max-width: 180px" />
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
import ApiService from '@/core/services/api.service.js'
export default {
  name: 'loader-comp',
  props: {
    logo: String,
    spinnerClass: String
  },
  data() {
    return {
      alternativeSettings: []
    }
  },
  computed: {
    logoImg() {
      return this.alternativeSettings.length > 0
        ? this.alternativeSettings.find((x) => x.Name === 'logo.img').Value
        : ''
    }
  },
  mounted() {
    this.getAlternativeSettingFromApi()
  },
  methods: {
    getAlternativeSettingFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Setting/alternative')
        .then((data) => {
          this.alternativeSettings = data.data
        })
    }
  }
}
</script>
